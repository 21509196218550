import React from 'react';

import BusinessFormMain from '../../components/dashboard/business/form/businessFormMain';
import DashboardLayout from '../../components/global/dashboard/dashboardLayout/dashboardLayout';
import { AuthPageBase } from '../../context/global-context';
import { AuthPageData, DashboardPageType } from '../../types/auth';
import { formTypes } from '../../types/forms';
import { LocalizationData } from '../../types/general';
import { PageContext } from '../../types/page';
import { getLocales, LocaleEnum, useI18n } from '../../utils/i18n';

type DashboardBusinessEditContext = PageContext & {
  locale: LocaleEnum;
  slug: string;
};

const DashboardBusinessEdit: React.FC<{
  id: number,
  pageContext: DashboardBusinessEditContext;
}> = ({ id, pageContext }) => {
  const { translations, locale, slug } = pageContext;

  const i18n = useI18n(translations);
  const locales = getLocales([locale]);
  const localizationsData: LocalizationData[] = [];

  locales.forEach((l) => {
    let slug = i18n.t('account.business.edit.slug', l);
    slug = slug ? slug : 'account/business';

    localizationsData.push({
      attributes: {
        slug: `${slug}/${id}`,
        locale: l,
      },
    });
  });

  const instance: AuthPageData = {
    dashboardPageType: DashboardPageType.editBusiness,
    pageEntityId: id,
    attributes: {
      slug: `${slug}/${id}`,
      locale: locale,
      localizations: {
        data: localizationsData,
      },
    },
  };

  return (
    <AuthPageBase translations={translations} instance={instance}>
      <DashboardLayout>
        <BusinessFormMain type={formTypes.edit} />
      </DashboardLayout>
    </AuthPageBase>
  );
};

export default DashboardBusinessEdit;
